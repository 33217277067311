import { Component } from "@angular/core";
import { CommonModule } from "@angular/common";
import { NzFormModule } from "ng-zorro-antd/form";
import { NzInputModule } from "ng-zorro-antd/input";
import { NzSelectModule } from "ng-zorro-antd/select";
import { NzButtonModule } from "ng-zorro-antd/button";
import { NzIconModule } from "ng-zorro-antd/icon";
import { BaseDetail } from "@app/base-components/detail";
import { WorkOperatorPool } from "@wearewarp/types/data-model/types/work-queue";
import { ActivatedRoute } from "@angular/router";
import { Const } from "@const/Const";
import { environment } from "@env/environment";
import { ManagePoolsService } from "../services/manage-pools.service";
import { User } from "@wearewarp/types/data-model";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NzGridModule } from "ng-zorro-antd/grid";
import { UIHelper } from "@services/UIHelper";
import { NzCheckboxModule } from "ng-zorro-antd/checkbox";

const CustomInputs = [
  CommonModule,
  FormsModule,
  ReactiveFormsModule,
  NzFormModule,
  NzInputModule,
  NzSelectModule,
  NzButtonModule,
  NzIconModule,
  NzGridModule,
  NzCheckboxModule
]

@Component({
  selector: 'operator-pool-detail',
  standalone: true,
  imports: [CustomInputs],
  templateUrl: './index.html',
  styleUrls: ['./index.scss'],
  providers: [ManagePoolsService],
})


export class OperatorPoolDetail extends BaseDetail<WorkOperatorPool> {
  listOperators: any[] = [];
  listClients: any[] = [];
  listCarriers: any[] = [];

  typeOptions = [
    { label: 'Trace & Trace', value: 'tracktrace' },
  ]

  loadTypeOptions = [
    { label: 'LTL', value: 'LTL' },
    { label: 'FTL', value: 'FTL' },
  ]

  protected formGroupDeclaration: FormGroupDeclaration = {
    name: { label: 'Pool Name', required: true },
    description: { label: 'Description', placeHolder: 'Note...' },
    type: { label: 'Pool Type', required: true, placeHolder: 'Select type' },
    condition: { label: 'Condition', required: true, type: 'formGroup', childItem: {
      clientIds: { label: 'Route For Customers', required: false, type: 'array' },
      carrierIds: { label: 'Route Of Carriers', required: false, type: 'array' },
      loadTypes: { label: 'Load Type', required: false }
    }},
    operatorIds: { label: 'Operator Pool', required: true, type: 'array', placeHolder: 'Select operators' },
    settings: { label: 'Settings', type: 'formGroup', childItem: {
      isAutoAssign: { label: 'Auto Assign', type: 'boolean', initialValue: true }
    }}
  }


  constructor(protected activatedRoute: ActivatedRoute, private managePoolsService: ManagePoolsService) {
    super(activatedRoute);
  }
  
  get saveText() {
    return this.isCreateNew ? 'Create new' : 'Save';
  }

  get shouldShowCancel() {
    return !this.isCreateNew;
  }

  ngOnInit() {
    super.ngOnInit();
    this.managePoolsService.getListOperators().subscribe(operators => {
      this.listOperators = operators.map(user => {
        return {
          label: user.fullName || `${user.firstName} ${user.lastName}`,
          value: user.id
        }
      })
    });
    this.managePoolsService.getListClients().subscribe(clients => {
      this.listClients = clients.map(client => {
        return {
          label: client.name,
          value: client.id
        }
      })
    });
  }

  protected getApiUrl(): string {
    return `${environment.backendUrl}/${Const.APIURI_OPERATOR_POOLS}`;
  }


  isSubmitting = false;
  onBtnSubmit() {
    const json = this.getFormData_JSON(true);
    let url = this.getApiUrl();
    if (!this.isCreateNew) {
      url = `${url}/${this.id}`;
    }
    this.isSubmitting = true;
    this.api.POST(url, json).subscribe({
      next: () => {
        this.isSubmitting = false;
        this.goToList();
      },
      error: (err) => {
        this.isSubmitting = false;
        UIHelper.showErr(err);
      }
    })
  }

  onBtnCancel() {
  }

  goToList() {
    this.router.navigate([Const.routeOperatorPoolList]);
  }

}