<div class="dashboard-child-container bg-white">
  <div class="list-header bottom10">
    <div class="header-actions">
      <div class="flex1"></div>
      <div class="action">
        <button nz-button nzType="primary" (click)="onBtnAdd()">Create Pool</button>
      </div>
    </div>

    <div class="header-filter">
    </div>

  </div>

  <div class="list-body">
    <nz-table
      #nzTable
      nzBordered="true"
      [nzData]="displayInfo.listData"
      nzSize="small"
      [nzShowPagination]="totalCount > listData.length"
      [nzPageIndex]="pageIndex"
      [nzLoading]="isLoading"
      [nzLoadingIndicator]="tplLoading"
      (nzPageIndexChange)="onDataListPageChanged($event)"
    >
      <ng-template #tplLoading>
        <div class="nodata"><i nz-icon nzType="loading" nzTheme="outline"></i></div>
      </ng-template>
      <thead>
        <tr>
          <th>Pool Name</th>
          <th>Description</th>
          <th nzWidth="200px">Number of Operators</th>
          <th nzWidth="160px">Pool Type</th>
          <th nzWidth="160px">Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of displayInfo.listData; let i = index">
          <td>
            <a [routerLink]="getLinkDetail(item)">
              {{ item?.name || 'N/A' }}
            </a>
          </td>
          <td>{{ item?.description || 'N/A' }}</td>
          <td>{{ item?.numberOfOperators || 'N/A' }}</td>
          <td>{{ item?.poolType || 'N/A' }}</td>
          <td>
            <button nz-button nzType="link" nzDanger (click)="onBtnDeletePool(item)">Delete</button>
          </td>
        </tr>
      </tbody>
    </nz-table>
  </div>

</div>