import { CommonModule } from "@angular/common";
import { Component } from "@angular/core";
import { NzTableModule } from "ng-zorro-antd/table";
import { NzButtonModule } from "ng-zorro-antd/button";
import { NzIconModule } from "ng-zorro-antd/icon";
import { NzInputModule } from "ng-zorro-antd/input";
import { NzSelectModule } from "ng-zorro-antd/select";
import { BaseList } from "@app/base-components/list";
import { Const } from "@const/Const";
import { ManagePoolsService } from "../services/manage-pools.service";
import { environment } from "@env/environment";
import { WorkOperatorPool } from "@wearewarp/types/data-model/types/work-queue";
import { User } from "@wearewarp/types/data-model";
import { RouterModule } from "@angular/router";

const CustomInputs = [
  CommonModule,
  RouterModule,
  NzTableModule,
  NzButtonModule,
  NzIconModule,
  NzInputModule,
  NzSelectModule,
]

@Component({
  selector: 'operator-pools',
  standalone: true,
  imports: [CustomInputs],
  templateUrl: './index.html',
  styleUrls: ['./index.scss'],
  providers: [ManagePoolsService],
})
export class ManageOperatorPoolList extends BaseList<WorkOperatorPool> {
  public listOperators: User[] = [];

  constructor(private managePoolsService: ManagePoolsService) {
    super();
  }

  displayInfo: any = {};

  ngOnInit() {
    super.ngOnInit();
    this.managePoolsService.getListOperators().subscribe((operators) => {
      this.listOperators = operators;
    })
  }

  protected getApiUrl(): string {
    return `${environment.backendUrl}/${Const.APIURI_OPERATOR_POOLS}`;
  }

  protected onGetDataSucceeded(resp) {
    this.buildDisplayInfo();
  }

  buildDisplayInfo() {
    this.displayInfo['listData'] = this.listData.map(item => {
      return {
        ...item,
        numberOfOperators: item.operatorIds.length,
        poolType: Const.OperatorPoolTypeLabel[item.type] || ''
      }
    });
  }

  onBtnDeletePool(item) {
  }

  getLinkDetail(item) {
    return [Const.routeOperatorPoolList, item.id];
  }
}