import { Injectable } from "@angular/core";
import { Const } from "@const/Const";
import { environment } from "@env/environment";
import { ApiService } from "@services/api.service";
import { Observable } from "rxjs";
import { Client, Job, User } from "@wearewarp/types/data-model";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class ManagePoolsService {
  public listOperators = new BehaviorSubject<User[] | null>(null);
  public listClients = new BehaviorSubject<Client[] | null>(null);
  public fetchingOperators = new BehaviorSubject<boolean>(false);

  constructor(private api: ApiService) {
  }
  
  getListOperators(): Observable<User[] | null> {
    if (this.fetchingOperators.value) {
      return this.listOperators.asObservable();
    }
    if (this.listOperators.value) {
      return this.listOperators.asObservable();
    }

    this.fetchingOperators.next(true);
    let url = `${environment.backendUrl}/${Const.APIURI_LIST_OPERATORS}`;
    const res = this.api.GET(url);
    res.subscribe(response => {
      this.listOperators.next(response.data?.list_data as unknown as User[]);
      this.fetchingOperators.next(false);
    });

    return this.listOperators.asObservable();
  }

  getListClients(): Observable<Client[] | null> {
    if (this.listClients.value) {
      return this.listClients.asObservable();
    }
    
    let url = `${environment.backendUrl}/${Const.APIURI_LIST_CLIENTS}`;
    const res = this.api.GET(url);
    res.subscribe(response => {
      this.listClients.next(response.data?.list_data as unknown as Client[]);
    });

    return this.listClients.asObservable();
  }
}
