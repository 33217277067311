<div class="dashboard-child-container bg-white">
  <form nz-form *ngIf="formInput" [formGroup]="formInput">
    <!-- Name -->
    <div *ngIf="'name' as key">
      <div class="form-label">{{ getLabel(key) }}<span *ngIf="isRequired(key)" class="label-mark-required"></span></div>
      <nz-form-item>
        <nz-form-control>
          <input nz-input [formControlName]="key" />
        </nz-form-control>
      </nz-form-item>
    </div>

    <!-- Type -->
    <div *ngIf="'type' as key">
      <div class="form-label">{{ getLabel(key) }}<span *ngIf="isRequired(key)" class="label-mark-required"></span></div>
      <nz-form-item>
        <nz-form-control>
          <nz-select [formControlName]="key" [nzPlaceHolder]="getPlaceHolder(key)">
            <nz-option *ngFor="let option of typeOptions" [nzValue]="option.value" [nzLabel]="option.label"></nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>
    </div>

    <!-- Condition -->
    <nz-row [nzGutter]="16">
      <nz-col [nzSpan]="10">
        <div *ngIf="'condition' as key" class="bottom20">
          <div class="form-label">{{ getLabel(key) }}</div>
          <div class="condition-box">
            <form nz-form [formGroupName]="key">
              <div *ngIf="'clientIds' as childKey">
                <div class="child-label">{{ getLabel(fullKey(key, childKey)) }}</div>
                <nz-form-item>
                  <nz-form-control>
                    <nz-select [formControlName]="childKey" [nzMode]="'multiple'" [nzPlaceHolder]="getPlaceHolder(childKey)" [nzShowSearch]="true">
                      <nz-option *ngFor="let option of listClients" [nzValue]="option.value" [nzLabel]="option.label"></nz-option>
                    </nz-select>
                  </nz-form-control>
                </nz-form-item>
              </div>

              <div *ngIf="'loadTypes' as childKey">
                <div class="child-label">{{ getLabel(fullKey(key, childKey)) }}</div>
                <nz-form-item>
                  <nz-form-control>
                    <nz-select [formControlName]="childKey" [nzMode]="'multiple'">
                      <nz-option *ngFor="let option of loadTypeOptions" [nzValue]="option.value" [nzLabel]="option.label"></nz-option>
                    </nz-select>
                  </nz-form-control>
                </nz-form-item>
              </div>
            </form>
          </div>
        </div>
      </nz-col>

      <nz-col [nzSpan]="4">
        <div class="forward-box"><i nz-icon nzType="forward" style="font-size: 24px;"></i></div>
      </nz-col>

      <nz-col [nzSpan]="10">
        <div *ngIf="'operatorIds' as key">
          <div class="form-label">{{ getLabel(key) }}<span *ngIf="isRequired(key)" class="label-mark-required"></span></div>
          <nz-form-item>
            <nz-form-control>
              <nz-select [formControlName]="key" [nzMode]="'multiple'" [nzPlaceHolder]="getPlaceHolder(key)" [nzShowSearch]="true">
                <nz-option *ngFor="let option of listOperators" [nzValue]="option.value" [nzLabel]="option.label"></nz-option>
              </nz-select>
            </nz-form-control>
          </nz-form-item>
        </div>
      </nz-col>
    </nz-row>

    <!-- Settings -->
    <div *ngIf="'settings' as key">
      <div class="form-label">{{ getLabel(key) }}</div>
      <div>
        <form nz-form [formGroupName]="key">
          <div *ngIf="'isAutoAssign' as childKey">
            <nz-form-item>
              <nz-form-control>
                <div nz-checkbox [formControlName]="childKey">{{ getLabel(fullKey(key, childKey)) }}</div>
              </nz-form-control>
            </nz-form-item>
          </div>
        </form>
      </div>
    </div>

    <!-- Description -->
    <div *ngIf="'description' as key">
      <div class="form-label">{{ getLabel(key) }}<span *ngIf="isRequired(key)" class="label-mark-required"></span></div>
      <nz-form-item>
        <nz-form-control>
          <textarea nz-input [formControlName]="key" [nzAutosize]="{ minRows: 2, maxRows: 5 }" [placeholder]="getPlaceHolder(key)"></textarea>
        </nz-form-control>
      </nz-form-item>
    </div>
    
  </form>

  <div class="form-footer top20">
    <button nz-button nzType="primary" (click)="onBtnSubmit()" [disabled]="isLoading || !needUpdate" [nzLoading]="isSubmitting">{{ saveText }}</button>
    <button nz-button nzType="default" (click)="onBtnCancel()" *ngIf="shouldShowCancel">Cancel</button>
  </div>
</div>