import { Directive } from "@angular/core";
import { BaseFormItem } from "./form-item";
import { ActivatedRoute } from "@angular/router";
import { Log } from "@services/log";
import { Utils } from "@services/utils";
import { UIHelper } from "@services/UIHelper";


@Directive()
export abstract class BaseDetail<T = any> extends BaseFormItem {
  protected _id = '';
  get id() { return this._id }

  isLoading: boolean = false;

  get shouldCreateFormImmediately() { return false}

  constructor(protected activatedRoute: ActivatedRoute) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  protected getTextCreateNew(): string {
    return 'Create new';
  }

  // Chỉ dùng sau khi update
  protected bindDataModel(model) {
    super.bindDataModel(model);
  }

  protected handleNavigationEnd(url: string, prevQueryParam: any): void {
    let prevId = this.id;
    this._id = this.getIdForDetailComponent(this.activatedRoute) || (this.currentUrl.includes('/add') ? 'add' : null);
    if (prevId == this.id) {
      return;
    }
    this.reset();
    this.isEditing = false;
    Log.d(`[${this.TAG}] handleNavigationEnd ${url}`);
    if (this.isCreateNew) {
      this.model = null;
      this.formInput = null;
      this.createFormInput();
      this.setEnableFormGroup(true);
    } else {
      this.getData();
    }
  }

  get isCreateNew(): boolean {
    return this.id === 'add';
  }
  get isReadonly(): boolean {
    return !this.isCreateNew && !this.isEditing;
  }

  // Chỉ dùng cho các component detail có url dạng như này /users/:id
  protected getIdForDetailComponent(route: ActivatedRoute) {
    return this.getRouteParam(route, 'id');
  }

  protected getRouteParam(route: ActivatedRoute, name: string = undefined) {
    if (name === undefined) {
      return (route.snapshot.paramMap as any).params;
    }
    let value = route.snapshot.params[name];
    if (value != undefined && value != null) {
      return decodeURIComponent(value);
    }
    return null;
  }

  protected onGetDetailSuccess(data: T): T {
    return data
  }

  protected abstract getApiUrl(): string;

  protected routeParam() {
    return null;
  }

  protected getData() {
    if (!this.getApiUrl()) {
      return Log.e(`[${this.TAG}] getApiUrl is empty`);
    }
    let url = `${this.getApiUrl()}/${this.id}`;
    this.isLoading = true;
    let routeParams = this.routeParam();
    if (Utils.isObjectNotEmpty(routeParams)) {
      let qs = new URLSearchParams(routeParams).toString();
      url += '?' + qs;
    }
    this.api.GET(url).subscribe({
      next: (resp) => {
        this.model = this.onGetDetailSuccess(resp.data);
        Log.d('getData model ', this.model);
        this.createFormInput(this.model);
        this.setEnableFormGroup(false);
        this.isLoading = false;
      },
      error: (err) => {
        this.isLoading = false;
        UIHelper.showErr(err);
      }
    })
  }



}